import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/logo.png"

const Header = ({ siteTitle }) => (
    <header className="top-header"
    style={{
    marginBottom: `1.45rem`,
    }}
    >
        <div
        style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1rem 1.0875rem`,
        }}
        >
            <Link
                to="/"
                style={{
                color: `white`,
                textDecoration: `none`,
                display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
            }}
            >
                <img
                    src={Logo}
                    width="60"
                    height="60"
                    alt="themuddshow-logo"
                    style={{marginBottom: 0, marginRight: 20}}
                />
                <h1 style={{ margin: 0 }}>
                {siteTitle}
                </h1>
            </Link>
        </div>
    </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
